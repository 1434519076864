import { Helmet } from "react-helmet-async";
import { useEffect, useState } from "react";
// @mui
import {
  Button,
  Chip,
  CircularProgress,
  Container,
  Dialog,
  DialogContent,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Grid,
  Paper,
  Stack,
  Tooltip,
  Typography,
  ButtonGroup,
} from "@mui/material";

import { db } from "../firebase.js";
import {
  addDoc,
  collection,
  doc,
  getDoc,
  getDocs,
  orderBy,
  updateDoc,
  where,
  query,
  serverTimestamp,
} from "firebase/firestore";
import { getStorage, ref, getDownloadURL } from "firebase/storage";
import { useUserAuth } from "../UserAuthContext";

import useResponsive from "../hooks/useResponsive";

import { useNavigate } from "react-router-dom";
import ShowMessage from "../components/ShowMessage/ShowMessage";
import ProjectList from "../components/ProjectList/ProjectList";

import Iconify from "../components/iconify";

import Templates from "../components/Templates/Templates";

import mixpanel from "mixpanel-browser";

// ----------------------------------------------------------------------

export default function HomePage() {
  const [projects, setProjects] = useState([]);
  const [projectsCount, setProjectsCount] = useState(0);

  const [selectedProject, setSelectedProject] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [loading, setLoading] = useState(true);
  const [openProjectTypeModal, setOpenProjectTypeModal] = useState(false);
  const [elevation, setElevation] = useState(12);
  const [selectedCardIndex, setSelectedCardIndex] = useState(0);
  const [showError, setShowError] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [listView, setListView] = useState(
    window.localStorage.getItem("view") === "list" || false
  );

  const { user } = useUserAuth();
  const isDesktop = useResponsive("up", "lg");

  const navigate = useNavigate();

  const handleMouseEnter = (index) => {
    setSelectedCardIndex(index);
    setElevation(12); // Set a higher elevation when hovering
  };

  const handleMouseLeave = () => {
    setElevation(8); // Reset elevation when not hovering
  };

  const cardData = [
    {
      icon: "/audio-waves.png",
      title: "Audio",
      description: "Advanced editor for creating AI-powered audio",
      type: "audio",
      highlights: [
        //"Ultra-real voices",
        //"Advanced editor",
        //"Combine multiple voices",
      ],
    },
    {
      icon: "/video.png",
      title: "Video",
      description: "Create stunning videos to elevate your content",
      type: "video",
      highlights: [
        //"Combine audio and visual elements", "Stock Media"
      ],
    },
    /*{
      icon: "fa-solid:video",
      title: "Video",
      description:
        "Create captivating videos by combining text to speech and video clips",
      type: "video",
      highlights: ["Voices with emotion", "Stock Media", "AI Writer"],
    },*/
  ];

  async function getProjects() {
    // console.log("loading projects");
    const q = query(
      collection(db, "projects"),
      where("user", "==", user.email),
      where("archived", "==", false),
      orderBy("created", "desc")
    );

    const querySnapshot = await getDocs(q);

    setProjectsCount(querySnapshot.docs.length);

    setProjects(
      querySnapshot.docs.map((doc) => ({
        id: doc.id,
        created: doc.data()["created"]?.toDate().toLocaleDateString(),
        name: doc.data()["name"],
        type: doc.data()["type"],
        isTemplate: doc.data()["isTemplate"],
      }))
    );

    return querySnapshot.size;
  }

  useEffect(() => {
    if (!user) {
      return;
    }

    const checkPendingTeamMembership = async () => {
      const q = query(
        collection(db, "teams"),
        where("members", "array-contains", user.email)
      );

      const querySnapshot = await getDocs(q);
      if (querySnapshot.empty) {
        // not part of a team
        return;
      }

      // Check if user has pending team membership

      const docRef = doc(db, "customers", user.uid);
      const docSnap = await getDoc(docRef);
      if (!docSnap.empty) {
        const userData = docSnap.data();
        // console.log(userData);
        if (!userData.team_join) {
          let path = `/teamaccept`;
          navigate(path, { state: { overquota: true } });
        }
      }
    };

    const checkUserIsVerified = () => {
      if (!user.tenantId && !user.emailVerified) {
        navigate("/vp", { replace: true });
      }
    };
    const loadHome = async () => {
      checkUserIsVerified();
      checkPendingTeamMembership();
      await getProjects();
    };

    loadHome().then(() => {
      setLoading(false);
    });
  }, [user]);

  const handleNewProjectDialog = async () => {
    if (projectsCount > 100) {
      setShowError(true);
      return;
    }
    setProjectsCount(projectsCount + 1);
    setOpenProjectTypeModal(true);
  };

  const handleNewProject = async (type = "audio") => {
    setProcessing(true);
    const docRef = await addDoc(collection(db, "projects"), {
      user: user.email,
      name: `Proj ${new Date().toLocaleDateString()}`,
      created: serverTimestamp(),
      archived: false,
      type: type,
      simple_mode: true,
    });
    // console.log(docRef.id);
    setProcessing(false);

    mixpanel.track("Create Project", { projectId: docRef.id });

    handleUserNavigation(docRef.id, type);
  };

  const handleUserNavigation = (projectId, type) => {
    // default audio
    let path = `/editor?id=${projectId}`;

    if (type === "video") {
      path = `/v/${projectId}`;
    }

    navigate(path);
  };

  const handleMenuOpen = (event, projectId) => {
    setAnchorEl(event.currentTarget);
    setSelectedProject(projectId);
    event.stopPropagation();
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleDelete = async () => {
    // console.log(selectedProject)
    const projectRef = doc(db, "projects", selectedProject);

    await updateDoc(projectRef, {
      archived: true,
    });

    await getProjects();
    handleMenuClose();
  };

  const closeProjectTypeModal = () => setOpenProjectTypeModal(false);

  const handleShowListView = (value) => {
    setListView(value);
    window.localStorage.setItem("view", value ? "list" : "grid");
  };

  const handleEdit = async () => {
    // when clicking the main card, selectedProject is not set
    handleUserNavigation(selectedProject);
  };

  if (loading) {
    return <CircularProgress />;
  }

  return (
    <>
      <Helmet>
        <title> Projects | Acoust </title>
      </Helmet>

      <Container>
        <Typography variant="h4" gutterBottom sx={{ mb: 5 }}>
          Projects
        </Typography>

        <Stack
          mb={projects.length === 0 ? 10 : 2}
          direction="row"
          alignItems="flex-end"
          justifyContent="space-between"
        >
          <Stack
            sx={{ width: "100%" }}
            direction={isDesktop ? "row" : "column"}
            spacing={2}
          >
            <Chip
              size="large"
              sx={{
                color: "white",
                width: isDesktop ? "200px" : "100%",
                height: "60px",
                border: "2px double transparent",
                backgroundImage:
                  "linear-gradient(rgb(13, 14, 33), rgb(13, 14, 33)), linear-gradient(90deg, rgba(93,26,138,1) 10%, rgba(202,10,10,1) 80%, rgba(233,145,21,1) 100%)",
                backgroundOrigin: "border-box",
                backgroundClip: "padding-box, border-box",
                boxShadow: "rgba(var(--primary-color), 0.5) 0px 0px 20px 0px",
              }}
              variant="outlined"
              disabled={processing}
              onClick={() => {
                handleNewProject();
              }}
              label={
                processing ? (
                  <CircularProgress size={20} />
                ) : (
                  <Stack direction="row" alignItems="center" spacing={2}>
                    <Iconify
                      color="#B71C1C"
                      icon="solar:soundwave-bold"
                      width="25px"
                    ></Iconify>
                    <Typography
                      sx={{
                        fontSize: "12px",
                      }}
                    >
                      Create Audio
                    </Typography>
                  </Stack>
                )
              }
            ></Chip>

            <Chip
              size="large"
              sx={{
                color: "white",
                width: isDesktop ? "200px" : "100%",
                height: "60px",
                border: "2px double transparent",
                backgroundImage:
                  "linear-gradient(rgb(13, 14, 33), rgb(13, 14, 33)), linear-gradient(90deg, rgba(93,26,138,1) 10%, rgba(202,10,10,1) 80%, rgba(233,145,21,1) 100%)",
                backgroundOrigin: "border-box",
                backgroundClip: "padding-box, border-box",
                boxShadow: "rgba(var(--primary-color), 0.5) 0px 0px 20px 0px",
              }}
              variant="outlined"
              disabled={processing}
              onClick={() => {
                handleNewProject("video");
              }}
              label={
                processing ? (
                  <CircularProgress size={20} />
                ) : (
                  <Stack direction="row" alignItems="center" spacing={2}>
                    <Iconify
                      color="#AA00FF"
                      width="25px"
                      icon="octicon:video-16"
                    ></Iconify>
                    <Typography
                      sx={{
                        fontSize: "12px",
                      }}
                    >
                      Create Video
                    </Typography>
                  </Stack>
                )
              }
            ></Chip>
          </Stack>
          {isDesktop && (
            <ButtonGroup>
              <Button
                size="small"
                onClick={() => handleShowListView(true)}
                sx={{ borderRadius: "10px" }}
              >
                <Iconify icon="material-symbols:list"></Iconify>
              </Button>
              <Button
                size="small"
                onClick={() => handleShowListView(false)}
                sx={{ borderRadius: "10px" }}
              >
                <Iconify icon="mingcute:grid-line"></Iconify>
              </Button>
            </ButtonGroup>
          )}
        </Stack>

        <Stack direction="row" justifyContent="center">
          {projects.length === 0 && (
            <iframe
              width="427"
              height="240"
              frameBorder="0"
              src={`https://www.youtube.com//embed/BiJZdQoCIyo`}
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              title="Embedded youtube"
            />
          )}
        </Stack>

        {isDesktop && listView ? (
          <ProjectList
            projects={projects}
            getProjects={getProjects}
            view="list"
          ></ProjectList>
        ) : (
          <Grid
            container
            spacing={2}
            justifyContent={
              isDesktop || projects.length === 1 ? "flex-start" : "center"
            }
          >
            {projects.map((project, index) => (
              <Grid item key={project.id}>
                <Paper
                  key={index}
                  elevation={index === selectedCardIndex ? elevation : 4}
                  onMouseEnter={() => handleMouseEnter(index)}
                  onMouseLeave={handleMouseLeave}
                  onClick={() => handleUserNavigation(project.id, project.type)}
                  sx={{
                    cursor: "pointer",
                    border: (theme) =>
                      index === selectedCardIndex
                        ? `0.5px solid ${theme.palette.grey[700]}`
                        : 0,
                  }}
                >
                  <Stack width={isDesktop ? 270 : 160} padding={2}>
                    {project.type && project.type === "video" ? (
                      <img
                        src={cardData[1].icon}
                        alt="Video"
                        style={{ maxWidth: "25px", height: "auto" }}
                      />
                    ) : (
                      <img
                        src={cardData[0].icon}
                        alt="Audio Content"
                        style={{ maxWidth: "25px", height: "auto" }}
                      />
                    )}
                    <Stack
                      mt={1}
                      spacing={2}
                      alignItems="center"
                      direction="row"
                    >
                      <Tooltip
                        title={project.name.length > 14 ? project.name : null}
                      >
                        <Typography
                          variant="h6"
                          display="block"
                          sx={{
                            overflow: "hidden",
                            whiteSpace: "nowrap",
                            textOverflow: "ellipsis",
                            maxWidth: "150px", // Set your desired maximum width
                            color: project.isTemplate ? "red" : "inherit",
                          }}
                        >
                          {project.name}
                        </Typography>
                      </Tooltip>
                    </Stack>

                    <Stack
                      mt={3}
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                      spacing={1}
                    >
                      <Typography
                        sx={{ color: "text.secondary", fontSize: "12px" }}
                      >
                        Created: {project.created}
                      </Typography>
                      <IconButton
                        aria-label="settings"
                        onClick={(e) => handleMenuOpen(e, project.id)}
                      >
                        <Iconify icon="mdi:dots-vertical"></Iconify>
                      </IconButton>
                    </Stack>
                  </Stack>
                </Paper>
              </Grid>
            ))}
          </Grid>
        )}

        {/* Carousel for templates */}
        <Stack mt={5}>
          <Typography variant="h5">Templates</Typography>
        </Stack>
        <Stack mt={2}>
          <Templates carousel={true}></Templates>
        </Stack>
        {/* END Carousel for templates */}

        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
          keepMounted
        >
          <MenuItem onClick={handleEdit}>
            <ListItemIcon>
              <Iconify icon="akar-icons:edit" />
            </ListItemIcon>
            <ListItemText>
              <Typography variant="button" display="block">
                Edit
              </Typography>
            </ListItemText>
          </MenuItem>

          <MenuItem onClick={handleDelete}>
            <ListItemIcon>
              <Iconify icon="material-symbols:delete-outline" />
            </ListItemIcon>
            <ListItemText>
              <Typography variant="button" display="block">
                Archive
              </Typography>
            </ListItemText>
          </MenuItem>
        </Menu>
      </Container>
      <Dialog
        fullWidth
        maxWidth="md"
        open={openProjectTypeModal}
        onClose={closeProjectTypeModal}
      >
        <DialogContent>
          <Grid container mt={4} justifyContent="space-evenly">
            {cardData.map((card, index) => (
              <Grid item key={index} mb={2}>
                <Paper
                  key={index}
                  elevation={index === selectedCardIndex ? elevation : 8}
                  onMouseEnter={() => handleMouseEnter(index)}
                  onMouseLeave={handleMouseLeave}
                  onClick={() => handleNewProject(card.type)}
                  sx={{ textAlign: "center" }}
                >
                  <Stack
                    key={index}
                    width={300}
                    padding={2}
                    spacing={4}
                    justifyContent="space-around"
                    alignItems="center"
                  >
                    <Stack
                      key={index}
                      spacing={1}
                      alignItems="center"
                      direction="row"
                    >
                      <img
                        src={card.icon}
                        alt="Audiobook"
                        style={{ maxWidth: "20px", height: "auto" }}
                      />
                      <Typography variant="h5" component="h2">
                        {card.title}
                      </Typography>
                      {index === 1 && (
                        <Chip
                          color="primary"
                          size="small"
                          sx={{
                            fontSize: "10px",
                          }}
                          label="BETA"
                        />
                      )}
                    </Stack>
                    <Typography variant="caption">
                      {card.description}
                    </Typography>
                    <Stack spacing={1} mb>
                      {card.highlights.map((highlight, index) => (
                        <Stack key={index} direction="row" spacing={1}>
                          <Iconify icon="fe:check" sx={{ color: "gray" }} />
                          <Typography
                            variant="caption"
                            sx={{ color: "text.secondary" }}
                          >
                            {highlight}
                          </Typography>
                        </Stack>
                      ))}
                    </Stack>
                    <Button
                      fullWidth
                      variant={"outlined"}
                      onClick={() => handleNewProject(card.type)}
                    >
                      Start
                    </Button>
                  </Stack>
                </Paper>
              </Grid>
            ))}
          </Grid>
        </DialogContent>
      </Dialog>
      <ShowMessage
        showFileError={showError}
        setShowFileError={setShowError}
        severity="error"
        message="Exceeded Max number of projects. Contact support if you need additional projects."
      />
    </>
  );
}
