import React, { useEffect, useRef, useState } from "react";
import { alpha } from "@mui/material/styles";

import {
  Box,
  Link,
  Slider,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";

import LoadingButton from "@mui/lab/LoadingButton";

import Iconify from "../iconify";

import ExportButton from "../ExportButton/ExportButton";
import ShowMessage from "../ShowMessage/ShowMessage";
import useResponsive from "../../hooks/useResponsive";

import ReactAudioPlayer from "react-audio-player";

export default function PlayerBar({
  enableExportAll,
  handleDownload,
  processing,
  setProcessing,
  handleMerge,
  isContentReady,
  audioPlayerUrl,
  mergedAudioGsUrl,
  processingDownload,
  autoPlay,
  projectType,
  fields,
  project,
  TriggerDownload,
  isPremiumUser,
  mode,
  previewAudioClipUrl,
  setPreviewAudioClipUrl,
  pauseMainAudio,
  setPauseMainAudio,
}) {
  const isDesktop = useResponsive("up", "lg");
  const playerRef = useRef();

  const [isAudioPlaying, setIsAudioPlaying] = useState(false);

  const [showMessage, setShowMessage] = useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("error");

  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);

  const handleShowMessage = (
    message = "Something went wrong. Please contact us if the issue persists",
    sev = "error"
  ) => {
    setShowMessage(true);
    setMessage(message);
    setSeverity(sev);
  };

  const handleSliderChange = (event, newValue) => {
    console.log(newValue);
    if (playerRef.current) {
      playerRef.current.audioEl.current.currentTime = newValue;
      setCurrentTime(newValue);
    }
  };

  const handleSkipButton = (value, skipForward = false) => {
    if (playerRef.current) {
      const currentTime = playerRef.current.audioEl.current.currentTime;
      const newTime = skipForward ? currentTime + value : currentTime - value;
      playerRef.current.audioEl.current.currentTime = newTime;
      setCurrentTime(newTime);
    }
  };

  function formatTime(timeInSeconds) {
    if (isNaN(timeInSeconds)) return "00:00";

    const minutes = Math.floor(timeInSeconds / 60);
    const seconds = Math.floor(timeInSeconds % 60);

    const formattedMinutes = String(minutes).padStart(2, "0");
    const formattedSeconds = String(seconds).padStart(2, "0");

    return `${formattedMinutes}:${formattedSeconds}`;
  }

  const handleAudioTimeUpdate = () => {
    if (playerRef.current) {
      setCurrentTime(playerRef.current?.audioEl.current.currentTime);
    }
  };

  useEffect(() => {
    if (previewAudioClipUrl && isAudioPlaying) {
      console.log("here");
      handlePlayPause();
    }
  }, [previewAudioClipUrl]);

  const handlePlayPause = async () => {
    console.log(previewAudioClipUrl);

    if (isAudioPlaying) {
      console.log("pausing audio");
      setIsAudioPlaying(false);
      playerRef.current.audioEl.current.pause();
      return;
    }

    if (previewAudioClipUrl) {
      setPreviewAudioClipUrl("");
    }

    var playPromise = playerRef.current.audioEl.current.play();
    if (playPromise !== undefined) {
      playPromise
        .then((_) => {
          setIsAudioPlaying(true);
        })
        .catch((error) => {
          setIsAudioPlaying(false);
          handleShowMessage();
          console.log(error);
        });
    }
  };

  const handleAudioStarted = () => {
    setIsAudioPlaying(true);
  };

  const handleAudioEnded = () => {
    // setAudioPlayerUrl(null);
    setIsAudioPlaying(false);
    setCurrentTime(0);
  };

  const handleMetadata = () => {
    setProcessing(false);
    setDuration(playerRef.current?.audioEl.current.duration || 0);
    //console.log("Audio duration:", duration);
  };

  return (
    <>
      <Box
        sx={{
          position: "fixed",
          bottom: mode === "stream" ? null : "8px",
          top: mode === "stream" ? "50vh" : null,
          left:
            mode === "stream" && isDesktop
              ? "80px"
              : isDesktop
              ? "240px"
              : "20px",
          right:
            mode === "stream" && isDesktop
              ? "80px"
              : isDesktop
              ? "30px"
              : "20px",
          zIndex: 5,
          height: mode === "stream" ? "100px" : "90px",
          borderRadius: "10px",
          boxShadow: 20,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          bgcolor: (theme) => theme.palette.background.paper,
          border: (theme) => `solid 1px ${theme.palette.divider}`,
        }}
      >
        <Stack flexGrow={1}>
          <Slider
            sx={{
              width: isDesktop ? "90%" : "80%",
              mx: "auto", // Center horizontally
            }}
            size="small"
            disabled={!audioPlayerUrl}
            value={currentTime}
            min={0}
            max={duration}
            onChange={handleSliderChange}
            valueLabelDisplay="auto"
            valueLabelFormat={(value) => formatTime(value)}
          />

          <Stack
            direction="row"
            justifyContent="space-between"
            padding={1}
            ml={2}
            mr={2}
          >
            <Stack direction="row" alignItems="center" spacing={2}>
              <Tooltip
                title={
                  audioPlayerUrl ? "" : "Generate all sections and play audio"
                }
              >
                <span>
                  <LoadingButton
                    sx={{
                      width: "40px", // Set the width of your button
                      height: "40px", // Set the height of your button
                      minWidth: "40px",
                      borderRadius: "50%",
                      //backgroundColor: allSectionsReady ? "inherit" : "#ccc",
                      backgroundImage: processing
                        ? "inherit"
                        : "linear-gradient(90deg, rgba(93,26,138,1) 0%, rgba(202,10,10,1) 70%, rgba(233,145,21,1) 100%)",
                    }}
                    loading={processing}
                    //disabled={!allSectionsReady}
                    color="primary"
                    onClick={audioPlayerUrl ? handlePlayPause : handleMerge}
                  >
                    {!processing && isAudioPlaying ? (
                      <Iconify
                        width="25px"
                        color="#fff"
                        icon="solar:pause-bold"
                      />
                    ) : (
                      !processing && (
                        <Iconify
                          width="25px"
                          color="#fff"
                          icon={"solar:play-bold"}
                        />
                      )
                    )}
                  </LoadingButton>
                </span>
              </Tooltip>
              <Stack direction="row">
                <IconButton
                  disabled={currentTime === 0}
                  onClick={() => handleSkipButton(10)}
                >
                  <Iconify icon="fluent:skip-backward-10-28-regular" />
                </IconButton>
                <IconButton
                  disabled={currentTime === 0}
                  onClick={() => handleSkipButton(30, true)}
                >
                  <Iconify icon="fluent:skip-forward-30-28-regular" />
                </IconButton>
              </Stack>
              <Stack direction="row">
                <Typography
                  variant="subtitle2"
                  sx={{
                    fontSize: "12px",
                    fontFamily: "Roboto Mono, monospace",
                  }}
                >
                  {formatTime(currentTime) || ""}
                </Typography>
                <Typography
                  variant="subtitle2"
                  sx={{
                    fontSize: "12px",
                    fontFamily: "Roboto Mono, monospace",
                  }}
                >
                  {"/" +
                    formatTime(playerRef.current?.audioEl.current.duration) ||
                    ""}
                </Typography>
              </Stack>
            </Stack>
            <ReactAudioPlayer
              src={audioPlayerUrl}
              onPlay={handleAudioStarted}
              onEnded={handleAudioEnded}
              //preload="metadata"
              // controls
              autoPlay={autoPlay}
              ref={playerRef}
              onListen={handleAudioTimeUpdate}
              listenInterval={1000}
              onLoadedMetadata={handleMetadata}
            />
            {mode !== "stream" && (
              <Stack justifyContent="center">
                <ExportButton
                  disable={!isContentReady}
                  processingDownload={processingDownload}
                  handleDownload={handleDownload}
                  enableExportAll={enableExportAll}
                  projectType={projectType}
                  isDesktop={isDesktop}
                  // allSectionsReady={allSectionsReady}
                  fields={fields}
                  project={project}
                  TriggerDownload={TriggerDownload}
                  isPremiumUser={isPremiumUser}
                />
              </Stack>
            )}
            {mode === "stream" && isDesktop && (
              <Stack justifyContent="center" mr={2}>
                <Typography variant="caption">
                  <Link
                    sx={{ color: "inherit" }}
                    href="https://acoust.io"
                    target="_blank"
                  >
                    Powered by Acoust AI
                  </Link>
                </Typography>
              </Stack>
            )}
          </Stack>
        </Stack>
      </Box>
      <ShowMessage
        showFileError={showMessage}
        setShowFileError={setShowMessage}
        severity={severity}
        message={message}
      />
    </>
  );
}
