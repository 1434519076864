import ContentCutRoundedIcon from "@mui/icons-material/ContentCutRounded";
import MicNoneOutlinedIcon from "@mui/icons-material/MicNoneOutlined";
import React, { useState } from "react";
import TimelineRuler from "./TimelineRuler";
import { Button } from "@mui/material";
import PlayButtonGroup from "../../components/Footer/components/PlayButtonGroup";
import CurrentTime from "../../components/Footer/components/CurrentTime";
import RightButtonGroup from "../../components/Footer/components/RightButtonGroup";
// import {CanvasStore} from "../../canvas/store/canvasStore";
// import canvasActions from "../../canvas/utils/canvasActions";

const TimelineHeader = ({
  scrollLeft,
  playing,
  currentTimeMs,
  animationDurationMs,
  onTogglePlay,
  onSeekbarUpdate,
  onPixelPerSecondChange,
  pixelsPerSecond,
}) => {
  const [zoom, setZoom] = useState(3);
  // const {activeObject,activeCanvas}=React.useContext(CanvasStore)

  const handleSeek = React.useCallback(
    (value) => {
      if (value < 0) value = 0;
      onSeekbarUpdate(value);
    },
    [onSeekbarUpdate]
  );

  const handleUpdateZoom = (value) => {
    setZoom(value);
  };

  const handleSpilt = async () => {
    // if(activeObject){
    //    let cloned=await canvasActions.duplicate(activeObject)
    //     activeObject.endTime=activeObject.startTime + currentTimeMs/1000 - activeObject.animationStartTime;
    //     cloned.startTime=activeObject.endTime;
    //     cloned.animationStartTime=currentTimeMs/1000;
    //     activeCanvas.add(cloned)
    //     // activeCanvas.fire('child:added',{target:cloned})
    // }
  };
  return (
    <>
      <div className="flex items-center h-14 mb-1">
        <div className="flex gap-2 items-center pl-4 flex-1">
          {/* <Button
            variant="outlined"
            sx={{
              fontSize: "13px",
            }}
          >
            <div className={"flex items-center"} onClick={handleSpilt}>
              <ContentCutRoundedIcon sx={{ fontSize: 16 }} />
              <span className={"ml-1"}>Split</span>
            </div>
          </Button>
          <Button
            variant="outlined"
            sx={{
              color: "#18191b",
              fontSize: "13px",
              border: 0,
              "&:hover": {
                background: "#eeeef0",
                border: 0,
              },
            }}
          >*/}
          {/*<div className={'flex items-center'}>*/}
          {/*    <MicNoneOutlinedIcon sx={{fontSize:18}}/>*/}
          {/*    <span className={'ml-1'}>*/}
          {/*        Voiceover*/}
          {/*    </span>*/}
          {/*</div>*/}
          {/*</Button>*/}
        </div>
        <PlayButtonGroup
          jumpTime={(frameTime) => handleSeek(currentTimeMs + frameTime)}
          isPlaying={playing}
          onTogglePlay={onTogglePlay}
        />
        <CurrentTime
          currentTime={currentTimeMs}
          timeLimit={animationDurationMs}
        />
        <RightButtonGroup
          onUpdateZoom={handleUpdateZoom}
          pixelsPerSecond={pixelsPerSecond}
          animationDuration={animationDurationMs}
          zoomValue={zoom}
        />
      </div>
      <div className="seek-player flex flex-col">
        <TimelineRuler
          animationDuration={animationDurationMs}
          onChange={handleSeek}
          height={30}
          zoom={zoom}
          onPixelPerSecondChange={onPixelPerSecondChange}
          scrollLeft={scrollLeft}
        />
      </div>
    </>
  );
};

export default TimelineHeader;
